@font-face {
    font-family: 'Franklin Gothic Regular';
    font-weight: normal;
    src: url("../assets/fonts/FranklinGothicURW-Boo.woff") format("woff")
}

@font-face {
    font-family: 'Franklin Gothic Medium';
    font-weight: normal;
    src: url("../assets/fonts/FranklinGothicURW-Med.woff") format("woff")
}

@font-face {
    font-family: 'Franklin Gothic Light';
    font-weight: normal;
    src: url("../assets/fonts/FranklinGothicURW-Lig.woff") format("woff")
}

:root {
    --primary-font-family: 'Franklin Gothic Regular';
    --medium-font-family: 'Franklin Gothic Medium';
    --light-font-family: 'Franklin Gothic Light';
    --brill-primary-green-color: #36AE78;
    --light-primary-color: #E3F4EC;
    --brill-black-color: #262626;
    --primary-dark-color: #1e1e1e;
    --primary-card-border: #272727;
    --primary-white-color: #FFFFFF;
    --brill-yellow-tint-color: #FCF3E1;
    --brill-yellow-color: #E2BF3A;
    --brill-mid-silver-color: #A8A8A9;
    --brill-os-pink-color: #D34563;
    --brill-os-pink--light-color: #F9E3E4;
    --brill-os-orange-color: #EE7748;
    --brill-os-orange-tint-color: #FFEADF;
    --brill-silver-color: #F4F4F4;

    // Dark-Mode Color Palette - IoS
    --dark-mode-background-color: #000000;
    --dark-mode-cards-background-color: #212124;
    --dark-mode-sidebar-background-color: #161618;
    --dark-mode-cards-divider-color: #A0A1A1;
}