.dark-mode {
    #content {
        min-height: 100vh;
        background-color: var(--dark-mode-background-color) !important;
    }

    .pi,
    .p-inputtext,
    .p-multiselect-label,
    .select-node-message,
    .p-listbox-option,
    .p-listbox-empty-message,
    .alignment,
    .title,
    .sub-title,
    h1,
    p,
    .p-tree {
        color: var(--primary-white-color) !important;
    }

    #sidebar ul li a:hover {

        span,
        .pi {
            color: var(--dark-mode-background-color) !important;
        }

    }

    .p-tree-node-content.p-tree-node-selectable:not(.p-tree-node-selected):hover,
    .p-tree-node-toggle-button:enabled:hover,
    .p-listbox:not(.p-disabled) .p-listbox-option:not(.p-listbox-option-selected):not(.p-disabled):hover,
    .p-listbox:not(.p-disabled) .p-listbox-option:not(.p-listbox-option-selected):not(.p-disabled).p-focus,
    .p-select-option:not(.p-select-option-selected):not(.p-disabled).p-focus,
    .p-select-option.p-select-option-selected,
    .p-multiselect-option:not(.p-multiselect-option-selected):not(.p-disabled).p-focus {
        background-color: rgba(255, 255, 255, 0.04) !important;
        color: var(--primary-white-color) !important;
    }

    .p-treetable .p-treetable-tbody>tr>td,
    .p-treetable .p-treetable-thead>tr>th {
        color: var(--primary-white-color) !important;
        background-color: var(--dark-mode-cards-background-color) !important;
    }

    .p-listbox .p-listbox-list .p-listbox-option.p-listbox-option-selected {
        background-color: var(--dark-mode-background-color) !important;
    }

    .p-checkbox-box {
        background-color: var(--dark-mode-background-color) !important;
    }

    .p-skeleton {
        background-color: var(--dark-mode-sidebar-background-color) !important;
    }

    .menu-space {
        background-color: var(--dark-mode-sidebar-background-color) !important;
    }

    .p-multiselect {
        background-color: var(--dark-mode-cards-background-color) !important;
        color: var(--primary-white-color) !important;
    }

    .p-multiselect-overlay {
        background-color: var(--dark-mode-cards-background-color) !important;
    }

    .highcharts-background {
        fill: var(--dark-mode-cards-background-color) !important;
    }

    .p-tree,
    .p-listbox {
        background: var(--dark-mode-cards-background-color) !important;
        border: none;
    }

    .p-dialog {
        border: none;
    }

    .highcharts-axis-labels,
    .highcharts-data-label {
        text {
            fill: var(--primary-white-color) !important;
            font-size: 11px !important;
            font-weight: normal !important;
            font-family: var(--primary-font-family) !important;

            tspan {
                stroke: none !important;
                font-weight: normal !important;
            }
        }
    }

    .highcharts-legend-item {
        text {
            fill: var(--primary-white-color) !important;
            font-size: 12px !important;
            font-family: var(--primary-font-family) !important;
        }
    }

    #sidebar .sidebar-header,
    .p-menubar {
        background-color: var(--dark-mode-sidebar-background-color) !important;
    }

    .card,
    .p-tab-active,
    .chart-loadingState {
        color: var(--primary-white-color) !important;
    }

    .highcharts-title {
        color: var(--primary-white-color) !important;
        fill: var(--primary-white-color) !important;
        font-family: var(--medium-font-family) !important;
        font-weight: normal !important;
    }

    hr.vertical {
        border-left: 1px solid var(--dark-mode-cards-divider-color) !important;
    }

    #sidebar {
        border-right: 2px solid var(--primary-card-border) !important;
    }

    .p-datatable .p-datatable-header,
    .p-datatable .p-datatable-thead>tr>th,
    .card,
    .p-dialog .p-dialog-header,
    .p-dialog .p-dialog-content,
    .p-dialog .p-dialog-footer,
    .p-listbox .p-listbox-header,
    .p-select,
    .p-autocomplete-input-multiple,
    .card-parent {
        background: var(--dark-mode-cards-background-color) !important;
        color: var(--primary-white-color) !important;
    }

    input[type=text] {
        border: 1px solid #D0D5DD !important;
    }

    .p-datatable-tbody>a>tr:hover,
    .notification-messages:hover {
        background: #707070 !important;
    }

    .selected-notification {
        background-color: #3d3d3d !important;
    }

    body,
    .p-autocomplete .p-autocomplete-input-multiple .p-autocomplete-chip {
        color: var(--primary-white-color) !important;
        background-color: #333333 !important;
    }

    .p-card {
        border: 2px solid var(--primary-card-border) !important;
    }

    .p-select .p-select-overlay {
        background-color: var(--dark-mode-cards-background-color) !important;
    }

    .p-menubar-submenu {
        background: #2b2b2b !important;
        border: none;
    }

    .p-tree .p-tree-filter-input {
        background-color: var(--dark-mode-cards-background-color) !important;
    }

    .p-menubar-item:not(.p-disabled)>.p-menubar-item-content:hover,
    .p-menubar-item-active>.p-menubar-item-content {
        background: #333333 !important;
    }

    .breadcrumb {
        background-color: var(--dark-mode-background-color) !important;
    }

    .breadcrumb a {
        color: var(--primary-white-color) !important;
    }

    .sect-card p,
    span {
        color: var(--primary-white-color) !important;
    }

    .headertext,
    .loadingState {
        color: var(--primary-white-color) !important;
    }


    #main-menu,
    #sub-menu,
    label,
    input[type=text],
    input[type=number] {
        color: var(--primary-white-color) !important;
        background-color: var(--dark-mode-cards-background-color) !important;
    }

    textarea {
        background-color: var(--dark-mode-cards-background-color) !important;
        color: var(--brill-primary-green-color) !important;
    }

    button:disabled {
        background-color: #494949 !important;
    }

    .cancel-btn>span,
    .cancel-btn:hover>span {
        color: var(--dark-mode-cards-background-color) !important;
    }

    .error-message {
        color: var(--primary-white-color) !important;
    }

    .btn {
        border: 1px solid var(--primary-white-color) !important;
        color: var(--primary-white-color) !important;
    }

    .collapsible-wrapper,
    .collapsible-wrapper.collapsed {
        border: 2px solid var(--primary-card-border) !important;
    }

    .collapsible-wrapper {
        background-color: var(--dark-mode-cards-background-color) !important;
    }

    .p-component {
        font-family: var(--primary-font-family) !important;
    }


    .form-control {
        background-color: var(--dark-mode-cards-background-color) !important;
    }

    .notification-title {
        color: var(--primary-white-color) !important;
    }

    .jsoneditor-text {
        background-color: var(--dark-mode-cards-background-color) !important;
        border: none !important;
    }

    .jsoneditor-menu,
    .jsoneditor-statusbar {
        background-color: var(--dark-mode-sidebar-background-color) !important;
    }

    .heatmap {
        th {
            background-color: var(--dark-mode-cards-background-color) !important;
        }
    }

    @media only screen and (max-width: 960px) {
        .p-menubar-mobile .p-menubar-root-list {
            background-color: var(--dark-mode-cards-background-color) !important;
        }

        .p-menubar {
            color: var(--primary-white-color) !important;
            background-color: var(--dark-mode-sidebar-background-color) !important;
        }

        #sidebar .mobile-sidebar-header {
            background-color: var(--dark-mode-sidebar-background-color) !important;
        }

        #sidebar {
            border-right: none !important;
        }

        barsicon {
            color: var(--primary-white-color) !important;
        }
    }

}