@import "/src/custom-styles/overrides";
@import "~jsoneditor/dist/jsoneditor.min.css";
@import '/src/assets/themes/brill-dark.scss';
@import '/src/assets/themes/brill-light.scss';

$sidebar_width : 230px;

.panel {
    box-shadow: none
}

.map-container {
    height: 400px;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0s;
}

// Sidebar style

#sidebar {
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    color: #fff;
    transition: all 0.3s;
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar .sidebar-header {
    padding: 15px 8px 0px 8px;
    background-color: var(--brill-silver-color);
    height: 60px;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    background: var(--brill-primary-green-color);
}

#content {
    width: calc(100% - $sidebar_width);
    transition: ease-in-out 0.5s;
    margin-left: $sidebar_width;
}

#content.active {
    width: 100%;
}

.headertext {
    font-family: var(--medium-font-family);
    font-size: 16px;
    color: var(--brill-black-color);
    height: 20px;
}

.subheadertext {
    font-family: var(--primary-font-family);
    color: var(--brill-black-color);
    font-size: 13px;
}

// Breadcrumb style

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 !important;
    font-size: 12px !important;
    margin: 15px 40px 0 40px !important;
    list-style: none;
    font-family: var(--primary-font-family);
    border-radius: 0px !important;
}

.breadcrumb-item.active {
    color: var(--brill-black-color) !important;
}

.breadcrumb-item a {
    color: var(--light-primary-color);
}

.breadcrumb-item a:hover {
    color: var(--brill-primary-green-color);
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #6c757d;
    content: "|" !important;
}

// Scrollbar style

::-webkit-scrollbar {
    width: 5px;
    height: 3px;
    overflow-x: hidden;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.12);
    min-height: 100px;
}

body {
    overflow-x: hidden;
    // Figure out what you can do here
    // line-height: 1.6 !important;
}

// Tabs style
.alignment {
    margin: 30px 0px 0 15px !important;
    height: 100%;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    transition: background-color 0.2s;
    border-radius: 0;
    text-decoration: none;
    padding: 15px 1rem;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:focus {
    background-color: inherit;
}

// p-autocomplete
.p-autocomplete .p-autocomplete-input-multiple input[type=text],
.p-autocomplete .p-autocomplete-input-multiple input[type=number] {
    font-size: 11px !important;
}

// date picker
.p-datepicker .p-datepicker-panel {
    z-index: 1;
}

// new p-tab style
.p-tab {
    font-size: 24px;
    font-family: var(--medium-font-family);
    font-weight: normal !important;
}

.p-tab-active {
    color: var(--brill-black-color) !important;
}

.p-tablist-active-bar {
    background: var(--brill-yellow-color) !important;
}

// .p-tabpanels {
//     padding: 0 !important;
// }

.p-tabpanels {
    padding: 16px 0 16px 0 !important;
}

.p-tabpanels,
.p-tablist-tab-list {
    background: none !important;
}

// new p-button
.p-button {
    border: none !important;
}

// new table style

.p-datatable-header {
    padding: 10px 0 !important;

}

// tree
.p-tree {
    border: none;
    padding: 10px 0px;

    .p-tree-filter-input {
        padding-right: 2rem;
        height: 30px !important;
        border-radius: 0px !important;
        border: none !important;
        border-bottom: 1px solid #ced4da !important;
        font-size: 12px !important;
        font-family: var(--primary-font-family) !important;
        width: 100%;
        margin-bottom: 10px;
    }

    .p-tree-node-label {
        font-family: var(--primary-font-family);
        color: var(--brill-black-color);
        font-size: 13px;
    }

    .p-tree-node-children {
        padding: 0 0 0 1rem !important;
    }

    .p-tree-node-selectable {
        padding: 5px;
    }
}

// new p-listbox
.p-listbox-option {
    padding: 0.6rem 0.5rem 0.6rem 1.1rem;
    font-size: 12px;
    color: var(--brill-black-color) !important;
}

.p-listbox-header {
    padding: 1rem !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.p-treetable .p-treetable-tbody>tr>td,
.p-treetable .p-treetable-thead>tr>th {
    color: var(--brill-black-color) !important;
}

// new p-dialog
.p-dialog {
    border-radius: 4px !important;
}


// new multiselect
.p-multiselect-option {
    font-size: 12px !important;

}

.p-multiselect-header {
    padding: 10px 15px 2px 12px !important;
}

// dropdown

.p-select-option,
.p-select-label,
.p-menubar-item-label {
    font-size: 12px !important;
    color: var(--brill-black-color) !important;
}

.p-select-option.p-select-option-selected.p-focus {
    background: rgba(159, 168, 218, 0.24) !important;
}

.p-select:not(.p-disabled).p-focus {
    border-color: var(--brill-primary-green-color) !important;
}

// p-menubarr 

.p-menubar-submenu {
    z-index: 1002 !important;
}

.p-menubar-submenu>.p-menubar-item-active .p-menubar-submenu {
    right: 100% !important;
    left: auto !important;
}

.p-menubar-submenu-icon {
    display: none !important;
}

.p-inputicon {
    top: 35% !important;
}

// Button style

.search-btn {
    border-radius: 3px !important;
    height: 35px !important;
    background-color: var(--brill-mid-silver-color) !important;
    color: white;
}

.search-btn:hover {
    background-color: var(--brill-mid-silver-color);
    color: white;
}

.search-btn:focus {
    background-color: var(--brill-mid-silver-color);
    color: white;
}

.search-btn:disabled {
    cursor: auto;
}

.cancel-btn,
.cancel-btn:hover {
    background-color: var(--primary-white-color) !important;
    border: 1px solid #dddddd !important;
    color: var(--brill-black-color) !important;
}

.list-buttons {
    padding: 10px 0;
    display: inline-block;
}

// Tooltip style 
.p-tooltip .p-tooltip-text {
    background: #ebf0ff !important;
    color: var(--dark-mode-cards-background-color) !important;
    font-size: 11px !important;
    border-radius: 5px !important;
    padding: 7px !important;
}

// Input/Forms style

input[type=text],
input[type=number] {
    width: 16rem;
    height: 35px;
    font-family: var(--primary-font-family);
    font-size: 12px;
    color: var(--brill-black-color);
    border: 1px solid #D0D5DD;
    padding-left: 15px;
    border-radius: 6px;
}

input:focus,
textarea:focus {
    border-color: #999;
    outline: none;
}

input[type=text]:focus-visible {
    border: 1px solid #D0D5DD;
}

textarea {
    width: 16rem;
    height: 60px;
    font-family: var(--primary-font-family);
    font-size: 12px !important;
    color: var(--brill-black-color);
    resize: none !important;
    border: 1px solid rgba(0, 0, 0, .125) !important;
    padding-left: 15px;
}

textarea:focus-visible {
    border: 1px solid rgba(0, 0, 0, .125);
}

label {
    color: var(--brill-black-color);
    font-family: var(--primary-font-family);
    font-size: 13px;
    font-weight: normal !important;
}

// Highchart roots style

.highcharts-root {
    font-family: var(--medium-font-family) !important;
}

.highcharts-label {
    text {
        tspan {
            font-weight: normal !important;
        }
    }

}

.highcharts-contextbutton .highcharts-button-box {
    display: none;
}

// Error Messages style

.node-selected-error-message {
    font-size: 15px;
    color: red;
}

// card

.card {
    border-radius: 5px !important;
    color: var(--brill-black-color) !important;
}

// Boorstrap 5 changes
// https: //getbootstrap.com/docs/5.1/migration/#utilities
// Renamed .ml-* and .mr-* to .ms-* and .me-*. 
// Renamed .pl-* and .pr-* to .ps-* and .pe-*.
ul {
    padding-left: 0 !important;
}

.placeholder {
    background-color: inherit;
}



// Media
@media only screen and (max-width: 960px) {
    #content {
        margin-left: 0;
        width: 100%;
        margin-top: 80px;
    }
}

@media only screen and (max-width: 750px) {
    .p-tablist-tab-list {
        overflow: scroll;
        overflow-y: hidden;
        max-width: 350px;
    }

    input[type=text],
    textarea {
        width: 15rem !important;
    }

    #content {
        margin-left: 0;
        margin-top: 80px;
    }

}