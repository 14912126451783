#content {
    background-color: var(--primary-white-color);
    min-height: 100vh;

}

.menu-space {
    background-color: var(--brill-silver-color);
}

#sidebar ul li a {
    color: var(--brill-black-color);
}

.highcharts-background {
    fill: var(--primary-white-color);
}

.highcharts-axis-labels,
.highcharts-data-label {
    text {
        fill: var(--primary-dark-color) !important;
        font-size: 11px !important;
        font-weight: normal !important;
        font-family: var(--primary-font-family) !important;
    }
}

.highcharts-legend-item {
    text {
        fill: var(--primary-dark-color) !important;
        font-size: 12px !important;
        font-family: var(--primary-font-family) !important;
    }
}

.highcharts-title {
    color: var(--primary-dark-color) !important;
    fill: var(--primary-dark-color) !important;
    font-family: var(--medium-font-family) !important;
    font-weight: normal !important;
}

.card {
    background-color: var(--primary-white-color) !important;
}

.p-card {
    border: 1px solid #EAECF0 !important;
}

.breadcrumb {
    background-color: var(--primary-white-color) !important;
    color: var(--primary-dark-color) !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link span {
    color: var(--primary-dark-color);
}


.p-component {
    font-family: var(--primary-font-family);
    font-size: 1rem;
    font-weight: normal;
}

.p-disabled,
.p-component:disabled {
    opacity: 0.38;
}


.sect-card {

    p {
        color: var(--brill-black-color);
    }

}